exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cv-tsx": () => import("./../../../src/pages/cv.tsx" /* webpackChunkName: "component---src-pages-cv-tsx" */),
  "component---src-pages-index-backup-tsx": () => import("./../../../src/pages/index_backup.tsx" /* webpackChunkName: "component---src-pages-index-backup-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-spell-tsx": () => import("./../../../src/pages/spell.tsx" /* webpackChunkName: "component---src-pages-spell-tsx" */),
  "component---src-pages-tools-snake-tsx": () => import("./../../../src/pages/tools/snake.tsx" /* webpackChunkName: "component---src-pages-tools-snake-tsx" */),
  "component---src-pages-tools-solr-tsx": () => import("./../../../src/pages/tools/solr.tsx" /* webpackChunkName: "component---src-pages-tools-solr-tsx" */)
}

